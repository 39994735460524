import React, { useState, useEffect, useRef } from 'react'
import { MapContainer } from "react-leaflet"
import { TileLayer, LayersControl, WMSTileLayer } from 'react-leaflet'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import TasksService from 'services/TasksService'

import LocationButton from "components/map/controls/LocationButton"
import GetCoordinates from "components/map/controls/GetCoordinates"
import InspectionsButton from "./InspectionsButton"

const basicLayersArray = [
    {
        type: "base",
        name: "OpenStreetMap",
        attribution: '&copy <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        maxZoom: 19
    },
    {
        type: "base",
        name: "Sentinel2 PNOA MA",
        attribution: '&copy <a target="_blank" href="https://www.scne.es/">Sistema Cartográfico Nacional de España</a> contributors',
        url: 'https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg',
        maxZoom: 19
    },
    {
        type: "wms",
        name: "SEDE Catastro",
        attribution: '&copy <a href="https://www.sedecatastro.gob.es/" target="_blank">Dirección General de Catastro</a>',
        //url: 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx',
        //layers: 'CATASTRO',
        //url: 'https://ovc.catastro.meh.es/cartografia/INSPIRE/spadgcwms.aspx',
        //layers: ['CP.CadastralParcel', 'CP.CadastralZoning', 'AD.Address', 'BU.Building', 'BU.BuildingPart'],
        //layers: ['CP.CadastralParcel', 'BU.Building'],
        //https://www1.sedecatastro.gob.es/cartografia/GeneraMapa.aspx?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CATASTRO&SRS=EPSG%3A3857&CRS=EPSG%3A3857
        url: 'https://www1.sedecatastro.gob.es/cartografia/GeneraMapa.aspx',
        layers: 'CATASTRO',
        format: 'image/png',
        transparent: true,
        maxZoom: 20
    },
    {
        type: "wms",
        name: "CARTOCIUDAD",
        attribution: '&copy <a href="https://www.cartociudad.es/" target="_blank">Cartociudad</a>',
        url: 'https://www.cartociudad.es/wms-inspire/direcciones-ccpp?SERVICE=WMS&',
        layers: ['AD.Address', 'codigo-postal'],
        format: 'image/png',
        transparent: true,
        maxZoom: 20
    }
]

const MapTasksModal = ({ groupId, show, callback, mapCenter, mapZoom }) => {
    const [map, setMap] = useState(null)
    const [fullscreen, setFullscreen] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [layersArray, setLayersArray] = useState(basicLayersArray)

    const mapRef = useRef(null)  // <-- Usamos useRef para el mapa
    const [bounds, setBounds] = useState(null)
    const [tasks, setTasks] = useState([])

    const handleClose = () => {
        callback(false)
    }

    const getTasks = async () => {
        if (!groupId || !bounds) return

        const { _southWest, _northEast } = bounds

        try {
            const response = await TasksService.getGroupTasks({
                groupId,
                lonbl: _southWest.lng,
                latbl: _southWest.lat,
                lonur: _northEast.lng,
                latur: _northEast.lat,
            })

            setTasks(response.data.tasks)
        } catch (error) {
            console.error("Error fetching tasks:", error)
        }
    }

    useEffect(() => {
        if (!map) return

        const updateBounds = () => {
            const newBounds = map.getBounds()
            setBounds(newBounds)
        }

        updateBounds() // Captura los bounds iniciales

        map.on("moveend", updateBounds)
        map.on("zoomend", updateBounds)

        return () => {
            map.off("moveend", updateBounds)
            map.off("zoomend", updateBounds)
        }
    }, [map])

    useEffect(() => {
        if (bounds) {
            getTasks()
        }
    }, [bounds, groupId])

    return (
        <Modal show={show} fullscreen={fullscreen} onHide={handleClose} >
            <Modal.Header closeButton={false} className={'p-2 bg-light'} >
                <Button size={'sm'} onClick={handleClose} variant={'light'}><i className="bi bi-arrow-left h4"></i></Button>
                <Modal.Title className="h6 d-flex justify-content-between align-items-center">
                    <i className="bi bi-map-fill"></i>&nbsp;Mapa tareas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <MapContainer
                    ref={mapRef}
                    key={"MapTasks"}
                    fullscreenControl={true}
                    //whenCreated={setMap}
                    whenReady={(map) => {
                        setMap(map.target)  // Asigna el mapa al ref
                    }}                    
                    center={mapCenter}
                    zoom={mapZoom}
                    scrollWheelZoom={true}
                    style={{ height: '100%', width: '100%' }}
                    maxZoom={19}
                >

                    <LayersControl position="topright" collapsed={true}>

                        {layersArray.map((layer, index) => {

                            switch (layer.type) {
                                case 'base':
                                    return (
                                        <LayersControl.BaseLayer
                                            key={"lc-baselayer-" + index}
                                            checked={index === 0 ? true : false}
                                            name={layer.name}
                                        >
                                            <TileLayer
                                                attribution={layer.attribution}
                                                url={layer.url}
                                                maxZoom={!layer.maxZoom ? 18 : layer.maxZoom}
                                                minZoom={!layer.minZoom ? 1 : layer.minZoom}
                                            />
                                        </LayersControl.BaseLayer>
                                    )

                                case 'wms':
                                    return (
                                        <LayersControl.Overlay
                                            key={"lc-overlay-" + index}
                                            checked={index === 0 ? true : false}
                                            name={layer.name}
                                        >
                                            <WMSTileLayer
                                                attribution={layer.attribution}
                                                url={layer.url}
                                                transparent={!layer.transparent ? false : true}
                                                format={layer.format}
                                                layers={layer.layers}
                                                maxZoom={!layer.maxZoom ? 18 : layer.maxZoom}
                                                minZoom={!layer.minZoom ? 1 : layer.minZoom}
                                                eventHandlers={{
                                                    add: (tile) => {
                                                        //console.log("Added WMS")
                                                    },
                                                    remove: (tile) => {
                                                        //console.log("Removed WMS")
                                                    }
                                                }}
                                            />
                                        </LayersControl.Overlay>
                                    )


                            }
                        })}

                    </LayersControl>

                    <LocationButton />

                    <GetCoordinates />

                    <InspectionsButton tasks={tasks} showTasks={true} />

                </MapContainer>
            </Modal.Body>
        </Modal >
    )
}

export default MapTasksModal