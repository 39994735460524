import { useState } from "react"
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Alert from 'react-bootstrap/Alert'

import moment from 'moment'

import "./accordion.css"

import { taskStatusProperties, taskResultProperties } from 'helpers/Tasks'

import InfoModal from "./InfoModal"
import MapModal from "./MapModal"
import PhotosModal from "./PhotosModal"
import ReportModal from './ReportModal'
import Pagination from "./Pagination"

const TasksList = ({ tasksList, pagination, group, mapCenter, mapZoom, updateTasksList }) => {
    //tasksList={tasksList} group={selectedGroup} mapCenter={mapCenter} mapZoom={mapZoom} getTasks={getTasks}
    const [taskSelected, setTaskSelected] = useState([])

    const [infoModalShow, setInfoModalShow] = useState(false)
    const [mapModalShow, setMapModalShow] = useState(false)
    const [photosModalShow, setPhotosModalShow] = useState(false)
    const [reportModalShow, setReportModalShow] = useState(false)

    const handleInfoBtn = (task) => {
        setTaskSelected(task)
        setInfoModalShow(true)
    }

    const handleMapBtn = (task) => {
        setTaskSelected(task)
        setMapModalShow(true)
    }

    const handlePhotosBtn = (task) => {
        setTaskSelected(task)
        setPhotosModalShow(true)
    }

    const handleTaskReportBtn = (task) => {
        setTaskSelected(task)
        setReportModalShow(true)
    }

    // Ordena las tareas por la fecha más reciente entre statusChangedAt y resultChangedAt, y luego por el nombre alfabéticamente
    const sortedTasks = tasksList
        ? [...tasksList].sort((a, b) => {
            // Encuentra la fecha más reciente entre statusChangedAt y resultChangedAt
            const dateA = new Date(Math.max(
                new Date(a.statusChangedAt).getTime(),
                new Date(a.resultChangedAt).getTime()
            ))
            const dateB = new Date(Math.max(
                new Date(b.statusChangedAt).getTime(),
                new Date(b.resultChangedAt).getTime()
            ))

            // Primero, ordena por fecha (más reciente primero)
            if (dateB - dateA !== 0) {
                return dateB - dateA // Orden descendente por fecha
            }

            // Si las fechas son iguales, ordena por task.name alfabéticamente
            return a.name.localeCompare(b.name) // Orden alfabético ascendente
        })
        : []

    return (
        <div style={{ marginBottom: 40 }}>
            {sortedTasks && sortedTasks.length > 0 ? (
                <>
                    <Accordion flush>
                        {sortedTasks && sortedTasks.length > 0 && sortedTasks.map((task, index) => {
                            task.name = task.feature.name
                            task.statusProperties = taskStatusProperties(task.status)
                            task.resultProperties = taskResultProperties(task.result, task.status)
                            return (
                                <Accordion.Item key={'accordion-task-' + task._id} eventKey={index}>
                                    <Accordion.Header bsPrefix={"my-accordion-header-" + task.resultProperties.bgcolor}>
                                        <i className={task.resultProperties.icon}></i>&nbsp;{task.name}
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                        <ListGroup variant="flush">
                                            <ListGroup.Item variant="light"><i className="bi bi-calendar3"></i> {moment(task.createdAt).format('DD/MM/YYYY, H:mm:ss')}</ListGroup.Item>

                                            <ListGroup.Item variant="light"><i className="bi bi-shield"></i> {group.event.name} / {group.name}</ListGroup.Item>

                                            {task.feature.properties && task.feature.properties['_message_'] &&
                                                <ListGroup.Item variant="light"><i className="bi bi-exclamation-triangle"></i> {task.feature.properties['_message_']}</ListGroup.Item>
                                            }

                                            <ListGroup.Item variant="light" className="d-flex w-100 align-items-center justify-content-between">
                                                <Badge bg={task.statusProperties.bgcolor}><i className={task.statusProperties.icon}></i> {task.statusProperties.text}</Badge>
                                                <small>{moment(task.statusChangedAt).fromNow()}</small>
                                            </ListGroup.Item>

                                            {task.result && task.result !== 'NONE' && (  // QUITO EL BOTON PARA QUE NO PUEDAN DESCARGAR PDF
                                                <ListGroup.Item variant="light" className="d-flex w-100 align-items-center justify-content-between">
                                                    <Badge bg={task.resultProperties.bgcolor}><i className={task.resultProperties.icon}></i> {task.resultProperties.text}</Badge>
                                                    <Button type="button" className={"btn btn-sm btn-" + task.resultProperties.bgcolor} onClick={() => handleTaskReportBtn(task)}><i className="bi bi-filetype-pdf"></i> Report</Button>
                                                    <small>{moment(task.resultChangedAt).fromNow()}</small>
                                                </ListGroup.Item>
                                            )}

                                            {task.resultDescription && task.resultDescription.length > 0 &&
                                                <ListGroup.Item variant="light"><i className="bi bi-card-text"></i> {task.resultDescription}</ListGroup.Item>
                                            }

                                            <ListGroup.Item variant="secondary">
                                                <div className="d-flex w-100 align-items-center justify-content-between p-0">
                                                    <Button variant="primary" size="sm" onClick={() => handleInfoBtn(task)}><i className="bi bi-info-square-fill"></i> Info</Button>{' '}
                                                    <Button variant="primary" size="sm" onClick={() => handleMapBtn(task)}><i className="bi bi-map-fill"></i> Mapa</Button>{' '}
                                                    <Button variant="primary" size="sm" onClick={() => handlePhotosBtn(task)}><i className="bi bi-camera-fill"></i> Fotos <Badge bg="secondary" pill>{task.pictures.length}</Badge></Button>{' '}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion >

                    <Pagination pagination={pagination} onPageChange={updateTasksList} />
                </>
            ) : (
                <div className="container p-1">
                    <Alert variant={'warning'}>
                        No task to show!
                    </Alert>
                </div>
            )}

            {infoModalShow && taskSelected &&
                <InfoModal task={taskSelected} group={group} callback={setInfoModalShow} />
            }
            {mapModalShow && taskSelected &&
                <MapModal task={taskSelected} group={group} callback={setMapModalShow} tasks={tasksList} mapCenter={mapCenter} mapZoom={mapZoom} />
            }
            {photosModalShow && taskSelected &&
                <PhotosModal task={taskSelected} group={group} callback={setPhotosModalShow} updateTasksList={updateTasksList} />
            }
            {reportModalShow && taskSelected &&
                <ReportModal task={taskSelected} group={group} callback={setReportModalShow} />
            }
        </div>
    )
}

export default TasksList