import React from "react"
import { Button } from "react-bootstrap"

const Pagination = ({ pagination, onPageChange }) => {
    if (!pagination || pagination.totalPages <= 1) return null
    const handlePageChange = (page) => {
        onPageChange(page)
    }
    return (
        <div className="flex text-center justify-center min-h-screen w-full mt-3 mb-5">
            <Button
                onClick={() => handlePageChange(pagination.prevPage)}
                disabled={!pagination.hasPrevPage}
                className="me-3"
            >
                Anterior
            </Button>
            <span className="text-sm font-medium">
                Página {pagination.page} de {pagination.totalPages}
            </span>
            <Button
                onClick={() => handlePageChange(pagination.nextPage)}
                disabled={!pagination.hasNextPage}
                className="ms-3"
            >
                Siguiente
            </Button>
        </div>
    )
}

export default Pagination
